import axios from "axios";
import { toast } from "react-toastify";

const apiClient = axios.create({
  baseURL: "https://una-shop.liara.run/api",
  timeout: 120000,
});

// apiClient.interceptors.request.use(function (request) {
//     const token = window.localStorage.getItem("token");

//     if (token) {
//       request.headers.Authorization = "Bearer " + token;
//     }

//     return request;
//   });

apiClient.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    if (error.response) {
      if (error.response.status === 404) {
        toast.error("There Are No Requested Resources.");
      } else if (error.response.status === 400) {
        toast.error("The Parameters Sent Are Not Correct");
      } else if (error.response.status === 401) {
        toast.error("Please Login");
      } else if (error.response.status === 403) {
        toast.error("You Do Not Have Access To These Resources");
      } else {
        toast.error("An Error Has Occurred");
      }
    } else if (error.request) {
      toast.error("The Connection To The Server Is Not Established");
      console.log(error.request);
    } else {
      toast.error("Unknown Error");
      console.log("Error", error.message);
    }

    return Promise.reject(error);
  },
);

export default apiClient;
