import { EntityType, ProductType } from "@/types";
import React, { createContext, ReactNode, useEffect, useState } from "react";

interface Props {
  children: ReactNode;
}

interface ProductItem {
  productId: number;
  image?: string;
  title: string;
  color: string;
  color_title: string;
  description?: string;
  price: number;
  sale_price?: number;
  quantity: number;
}

export const BasketContext = createContext<{
  basketItems: Array<ProductItem>;
  basketTotalPrice: number;
  addItem: (product: ProductItem) => void;
  increaseItem: (productId: number) => void;
  decreaseItem: (productId: number) => void;
  deleteItem: (productId: number) => void;
  deleteAllItems: () => void;
  getBasketItem: (productId: number) => undefined | ProductItem;
  storageBasketItems: Array<ProductItem>;
  setDeliveryFee: (productId: number) => void;
  deliveryFee: number;
}>({
  basketItems: [],
  basketTotalPrice: 0,
  addItem: (product: ProductItem) => {},
  increaseItem: (productId: number) => {},
  decreaseItem: (productId: number) => {},
  deleteItem: (productId: number) => {},
  deleteAllItems: () => {},
  getBasketItem: (productId: number) => undefined,
  storageBasketItems: [],
  setDeliveryFee: (productId: number) => undefined,
  deliveryFee: 0,
});

export default function BasketContextProvider(props: Props) {
  const [basketItems, setBasketItems] = useState<Array<ProductItem>>([]);

  const [deliveryFee, setDeliveryFee] = useState<number>(0);

  const storageBasketItems: Array<ProductItem> = [];

  let price: Array<number> = [];
  let totalPrice: number = 0;

  basketItems.map((basketItem) => {
    if (basketItem.sale_price) {
      const totalPrice = basketItem.sale_price * basketItem.quantity;
      price.push(totalPrice);
    } else {
      const totalPrice = basketItem.price * basketItem.quantity;
      price.push(totalPrice);
    }
  });

  if (price.length > 1) {
    totalPrice = price.reduce((a, d) => a + d);
  } else {
    totalPrice = price[0];
  }

  useEffect(() => {
    if (!window.localStorage.getItem("basket")) {
      window.localStorage.setItem("basket", JSON.stringify([]));
    } else {
      const basketJson = window.localStorage.getItem("basket")!;
      const basket = JSON.parse(basketJson);
      setBasketItems(basket);
    }
  }, []);

  const addItemHandler = (product: ProductItem) => {
    const newProduct: ProductItem = {
      productId: product.productId,
      image: product.image,
      title: product.title,
      color: product.color,
      color_title: product.color_title,
      price: product.price,
      sale_price: product.sale_price,
      quantity: product.quantity,
    };

    const currentProduct = getBasketItemHandler(product.productId);

    if (currentProduct) {
      increaseItemHandler(product.productId);
    } else {
      setBasketItems((prevState) => {
        window.localStorage.setItem("basket", JSON.stringify([...prevState, newProduct]));
        return [...prevState, newProduct];
      });
    }
  };

  const increaseItemHandler = (productId: number) => {
    const newBasket = basketItems.map((basketItem) => {
      if (basketItem.productId === productId) {
        return { ...basketItem, quantity: basketItem.quantity + 1 };
      } else {
        return basketItem;
      }
    });

    setBasketItems(newBasket);
  };

  const decreaseItemHandler = (productId: number) => {
    const currentProduct = getBasketItemHandler(productId);

    if (currentProduct && currentProduct.quantity === 1) {
      deleteItemHandler(productId);
    } else {
      const newBasket = basketItems.map((basketItem) => {
        if (basketItem.productId === productId) {
          return { ...basketItem, quantity: basketItem.quantity - 1 };
        } else {
          return basketItem;
        }
      });

      setBasketItems(newBasket);
    }
  };

  const deleteItemHandler = (productId: number) => {
    const newBasket = basketItems.filter((basketItem) => basketItem.productId !== productId);
    window.localStorage.setItem("basket", JSON.stringify([]));
    setBasketItems(newBasket);
  };

  const deleteAllItemsHandler = () => {
    setBasketItems([]);
    window.localStorage.setItem("basket", JSON.stringify([]));
  };

  const getBasketItemHandler = (productId: number): ProductItem | undefined => {
    return basketItems.find((basketItem) => basketItem.productId === productId);
  };

  return (
    <BasketContext.Provider
      value={{
        basketItems: basketItems,
        basketTotalPrice: totalPrice,
        addItem: addItemHandler,
        increaseItem: increaseItemHandler,
        decreaseItem: decreaseItemHandler,
        deleteItem: deleteItemHandler,
        deleteAllItems: deleteAllItemsHandler,
        getBasketItem: getBasketItemHandler,
        storageBasketItems: storageBasketItems,
        setDeliveryFee: setDeliveryFee,
        deliveryFee: deliveryFee,
      }}
    >
      {props.children}
    </BasketContext.Provider>
  );
}
