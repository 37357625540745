import { ApiResponseSingleType, ApiResponseType, ProductType } from "@/types";
import apiClient from "./config/ApiClient";

interface Props {
  filters?: {};
  sort?: Array<string>;
  pagination?: {
    withCount?: boolean;
    page?: number;
    pageSize?: number;
    start?: number;
    limit?: number;
    total?: number;
    pageCount?: number;
  };
}

interface ProductID {
  productID: string | string[] | undefined;
}

export async function getProductsApiCall(): Promise<ApiResponseType<ProductType>> {
  return await apiClient.get("/products", {
    params: {
      populate: "*",
    },
  });
}

export async function getFilteredProductsApiCall({
  filters = {},
  sort = [],
  pagination = {},
}: Props): Promise<ApiResponseType<ProductType>> {
  return await apiClient.get("/products", {
    params: {
      populate: "*",
      filters: filters,
      sort: sort,
      pagination: pagination,
    },
  });
}

export async function getSingleProductApiCall({
  productID,
}: ProductID): Promise<ApiResponseSingleType<ProductType>> {
  return apiClient.get(`/products/${productID}`, {
    params: {
      populate: "*",
    },
  });
}
